<!-- 转运调度统计 By阿旭 -->
<template>
  <div class="main">
    <div class="row">
      <el-select style="width: 230px; margin-right: 20px" v-model="transportOrgCode" filterable clearable
        placeholder="负责机构">
        <el-option v-for="item in homeIsolationOptions" :key="item.value" :label="item.orgName" :value="item.orgCode">
        </el-option>
      </el-select>
      <el-date-picker v-model="ldtime" unlink-panels size="medium" type="daterange" value-format="yyyy-MM-dd"
        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      <el-button type="primary" size="small" @click="init" style="margin-left: 20px; width: 100px">查看</el-button>
      <el-button type="primary" size="small" @click="escalateExport('btn')" style="margin-left: 20px; width: 100px">导出
      </el-button>
    </div>
    <div class="row" style="width: 100%; display: flex; align-items: center">
      <el-card style="width: 180px; text-align: center; margin-right: 20px">
        <p>分配转运任务</p>
        <div style="border-top: 1px solid #dcdfe6; margin: 6px 0"></div>
        <p style="font-weight: 600">{{ numCount.taskNum }}人</p>
      </el-card>
      <el-card style="width: 180px; text-align: center; margin-right: 20px">
        <p>转运人次</p>
        <div style="border-top: 1px solid #dcdfe6; margin: 6px 0"></div>
        <p style="font-weight: 600">{{ numCount.personNum }}人</p>
      </el-card>
      <el-card style="width: 180px; text-align: center">
        <p>转运工作人员</p>
        <div style="border-top: 1px solid #dcdfe6; margin: 6px 0"></div>
        <p style="font-weight: 600">{{ numCount.workerNum }}人</p>
      </el-card>
    </div>

    <div class="zyrqfb">
      <div class="transportPopulationsEcharts">
        <span style="white-space: nowrap">转运人群分布</span>
        <div id="transportPopulationsEcharts"></div>
      </div>
      <div class="zyrqfb_table">
        <el-table :header-cell-style="{ background: 'rgb(245, 245, 245)', color: '#606266' }" ref="table"
          :data="classDistributed" border max-height="300" :summary-method="getSummaries" show-summary
          style="width:100%;margin: 20px 20px 20px 0px">
          >
          <el-table-column prop="name" label="人群分类" align="center" width="260"></el-table-column>
          <el-table-column prop="value" label="人数" align="center" width="260"></el-table-column>
        </el-table>
      </div>
    </div>
    <br>
    <div class="zyrqfb">
      <div class="distributionIsolation">
        <span style="white-space: nowrap">送往隔离点分布情况</span>
        <div id="distributionIsolation"></div>
      </div>

      <!-- 表格box -->
      <div class="zyrqfb_table">
        <el-table :header-cell-style="{ background: 'rgb(245, 245, 245)', color: '#606266' }" ref="table"
          :data="isolationDistributed" border max-height="300" :summary-method="getSummaries" show-summary
          style="margin: 20px 20px 20px 0px">
          >
          <el-table-column prop="name" label="隔离点" align="center" width="260"></el-table-column>
          <el-table-column prop="value" label="人数" align="center" width="260"></el-table-column>
        </el-table>
      </div>
    </div>
    <br>
    <div class="zyrqfb">
      <div class="summary">
        <span style="white-space: nowrap">转运任务处理汇总</span>
        <div id="includeSummary">
          <div id="summary" ref="summary"></div>
        </div>
      </div>
      <div class="zyrqfb_table">
        <el-table :header-cell-style="{ background: 'rgb(245, 245, 245)', color: '#606266' }" ref="table"
          :data="communityCount" border max-height="300" :summary-method="getSummaries" show-summary
          style="width:100%;margin: 20px 20px 20px 0px">
          >
          <el-table-column prop="name" label="转运任务机构" align="center" width="173"></el-table-column>
          <el-table-column prop="series1" label="任务数量" align="center" width="173"></el-table-column>
          <el-table-column prop="series2" label="接送人数" align="center" width="173"></el-table-column>
        </el-table>
      </div>
    </div>
    <br>
    <div class="zyrqfb">
      <div class="driversummary">
        <span style="white-space: nowrap">司机任务处理汇总</span>
        <div id="includeDriver">
          <div id="driversummary" ref="driversummary"></div>
        </div>
      </div>
      <div class="zyrqfb_table">
        <el-table :header-cell-style="{ background: 'rgb(245, 245, 245)', color: '#606266' }" ref="table"
          :data="driverCount" border max-height="300" :summary-method="getSummaries" show-summary
          style="width:100%;margin: 20px 20px 20px 0px">
          >
          <el-table-column prop="name" label="司机" align="center" width="173"></el-table-column>
          <el-table-column prop="series1" label="任务数量" align="center" width="173"></el-table-column>
          <el-table-column prop="series2" label="接送人数" align="center" width="173"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { importFile, messageBox } from "@/utils/utils";
import * as echarts from "echarts";
import {
  transportScheduleCount,
  exportList,
} from "@/api/Taskmanagement/TransitSchedulingStatistics";
import moment from "moment";
import { getBusinessOrgList } from "@/api/SystemManagement/index";

export default {
  data() {
    return {
      homeIsolationOptions: [],
      transportOrgCode: "", //转运机构
      ldtime: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      youb: {},
      numCount: {},
      regitsterInfo: [],
      classDistributed: [],
      classDistributednub: 0,
      communityCountnub: 0,
      isolationDistributednub: 0,
      communityCountnub1: 0,
      communityCountnub2: 0,
      isolationDistributed: [],
      communityCount: [],
      driverCount: [],
      driverCountNub: 0,
      driverCountNub1: 0,
      driverCountNub2: 0,
    };
  },

  components: {},

  computed: {},
  created() {
    this.transportScheduleCount();
    this.orgHomeIsolationList(); //根据业务状态查询机构
  },
  mounted() {
    // this.gldb()
    // this.sqws()
  },
  updated() {
    this.$nextTick(() => {
      this.$refs["table"].doLayout();
    });
  },

  methods: {
    getSummaries(param) {
      console.log(param, "打印param");
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index];
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },

    orgHomeIsolationList() {
      // 获取业务状态获取不同的机构
      let params = {
        transshipmentDispatching: 1,
        orgCode: this.userdoctor.orgCode,
      };
      getBusinessOrgList({ params }).then((res) => {
        this.homeIsolationOptions = res.data.data;
      });
    },
    // 查看btn
    init() {
      this.transportScheduleCount();
    },
    // 转运人群分布
    rqfl() {
      // console.log(123123);
      function fontSize(res) {
        let docEl = document.documentElement,
          clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (!clientWidth) return;
        // 此处的3840 为设计稿的宽度，记得修改！
        let fontSize = clientWidth / 1920;
        return res * fontSize;
      }
      var chartDom = document.getElementById("transportPopulationsEcharts");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            data: this.classDistributed,
            // data: this.youb.distributed.map((item, index) => {
            //   item.label = {
            //     color: "#49C9FF",
            //   };
            //   return item;
            // }),
            emphasis: {
              itemStyle: {
                color: "#fff",
              },
            },
            normal: {
              label: {
                show: true,
                formatter: "{ b } : { c }({ d } %)",
              },
              labelLine: { show: true },
            },
          },
        ],
      };
      option && myChart.setOption(option);
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        // console.log(123);

        myChart.resize();
      });
    },

    // 隔离点分布
    gldb() {
      function fontSize(res) {
        let docEl = document.documentElement,
          clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (!clientWidth) return;
        // 此处的3840 为设计稿的宽度，记得修改！
        let fontSize = clientWidth / 1920;
        return res * fontSize;
      }
      var chartDom = document.getElementById("distributionIsolation");
      var myChart = echarts.init(chartDom);
      var option;
      // console.log(
      //   "🚀 ~ file: Largescreen.vue ~ line 743 ~ rqfl ~ this.youb.distributed",
      //   this.youb
      // );

      option = {
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            data: this.isolationDistributed,
            // data: this.youb.distributed.map((item, index) => {
            //   item.label = {
            //     color: "#49C9FF",
            //   };
            //   return item;
            // }),
            emphasis: {
              itemStyle: {
                color: "#fff",
              },
            },
            normal: {
              label: {
                show: true,
                formatter: "{ b } : { c }({ d } %)",
              },
              labelLine: { show: true },
            },
          },
        ],
      };

      option && myChart.setOption(option);
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        // console.log(123);

        myChart.resize();
      });
    },

    // 社区卫生服务
    sqws() {
      function fontSize(res) {
        let docEl = document.documentElement,
          clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (!clientWidth) return;
        // 此处的3840 为设计稿的宽度，记得修改！
        let fontSize = clientWidth / 1920;
        return res * fontSize;
      }
      // var chartDom = document.getElementById("summary");
      var chartDom  = this.$refs.summary;
      var myChart = echarts.init(chartDom);
      var option;
      let xdata = this.communityCount;
      // console.log("🚀 ~  xdata", xdata)
      option = {
          toolbox: {
            feature: {
              dataView: {}
            }
          },
          tooltip: {
            trigger: "item",
            formatter: "{b} : {c}",
          },
          dataZoom: [{
            type: "slider",
            show: true,
            startValue: 0,
            endValue: 6,
          }],
          xAxis: {
            type: "category",
            data: xdata.map((item) => {
              return item.name.split("(")[0];
            }),
          },
          // y轴
          yAxis: [
            {
              type: "value",
              splitLine: {
                show: true,
                lineStyle: {
                  color: "#E0E6F1",
                },
              },
              axisLabel: {
                color: "#6E7079",
              },
            },
          ],
          series: [
            {
              name: "任务数量",
              type: "bar",
              data: xdata.map((item) => {
                return item.series1; //任务数量
              }),
              barWidth: fontSize(20),
              itemStyle: {
                color: "#5470C6",
              },
            },
            {
              name: "接送人数",
              type: "bar",
              data: xdata.map((item) => {
                return item.series2; //接送人数
              }),
              barWidth: fontSize(20),
              itemStyle: {
                color: "#91CC75",
              },
            },
          ],
        };

      option && myChart.setOption(option);
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        // console.log(123);

        myChart.resize();
      });
    },

    // 司机任务处理汇总
    driver() {
      function fontSize(res) {
        let docEl = document.documentElement,
          clientWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (!clientWidth) return;
        // 此处的3840 为设计稿的宽度，记得修改！
        let fontSize = clientWidth / 1920;
        return res * fontSize;
      }
      // var driversummaryChartDom = document.getElementById("driversummary");
      var driversummaryChartDom = this.$refs.driversummary;
      // console.log(driversummaryChartDom, "chartDomchartDom");
      let driversummaryChart = echarts.init(driversummaryChartDom);
      // console.log(driversummaryChart, "driversummaryChart11111");
      let xdata = this.driverCount;
      var option,
        // console.log(xdata, "打印xdata");
        // console.log("🚀 ~  xdata", xdata)
        //  option = {
        //   dataZoom: [
        //     // 有滚动条 平移
        //     {
        //       type: "slider",
        //       realtime: true,
        //       show: xdata.length > 8,
        //       start: 0,
        //       end: 100, // 初始展示40%
        //       height: 4,
        //       fillerColor: "rgba(17, 100, 210, 0.42)", // 滚动条颜色
        //       borderColor: "rgba(17, 100, 210, 0.12)",
        //       handleSize: 0, // 两边手柄尺寸
        //       showDetail: false, // 拖拽时是否展示滚动条两侧的文字
        //       top: "96%",

        //       // zoomLock:true, // 是否只平移不缩放
        //       // moveOnMouseMove:true, //鼠标移动能触发数据窗口平移
        //       // zoomOnMouseWheel :true, //鼠标移动能触发数据窗口缩放
        //     },
        //     {
        //       type: "inside", // 支持内部鼠标滚动平移
        //       start: 0,
        //       end: 40,
        //       zoomOnMouseWheel: false, // 关闭滚轮缩放
        //       moveOnMouseWheel: true, // 开启滚轮平移
        //       moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
        //     },
        //   ],
        //   legend: {
        //     data: ["任务数量", "接送人数"],
        //   },
        //   tooltip: {
        //     trigger: "axis",
        //   },

        //   calculable: true,

        //   // x轴
        //   xAxis: [
        //     {
        //       type: "category",
        //       data: xdata.map((item) => {
        //         return item.name.split("(")[0];
        //       }),

        //       axisLabel: {
        //         interval: 0,
        //         formatter: function (value) {
        //           var ret = ""; //拼接加\n返回的类目项
        //           var maxLength = 10; //每项显示文字个数
        //           var valLength = value.length; //X轴类目项的文字个数
        //           var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
        //           if (rowN > 1) {
        //             //如果类目项的文字大于5,
        //             for (var i = 0; i < rowN; i++) {
        //               var temp = ""; //每次截取的字符串
        //               var start = i * maxLength; //开始截取的位置
        //               var end = start + maxLength; //结束截取的位置
        //               //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
        //               temp = value.substring(start, end) + "\n";
        //               ret += temp; //凭借最终的字符串
        //             }
        //             return ret;
        //           } else {
        //             return value;
        //           }
        //         },
        //       },
        //     },
        //   ],
        //   // y轴
        //   yAxis: [
        //     {
        //       type: "value",
        //       splitLine: {
        //         show: true,
        //         lineStyle: {
        //           color: "#E0E6F1",
        //         },
        //       },
        //       axisLabel: {
        //         color: "#6E7079",
        //       },
        //     },
        //   ],
        //   series: [
        //     {
        //       name: "任务数量",
        //       type: "bar",
        //       data: xdata.map((item) => {
        //         return item.series1; //任务数量
        //       }),
        //       barWidth: fontSize(20),
        //       itemStyle: {
        //         color: "#5470C6",
        //       },
        //     },
        //     {
        //       name: "接送人数",
        //       type: "bar",
        //       data: xdata.map((item) => {
        //         return item.series2; //接送人数
        //       }),
        //       barWidth: fontSize(20),
        //       itemStyle: {
        //         color: "#91CC75",
        //       },
        //     },
        //   ],
        // };


        option = {
          toolbox: {
            feature: {
              dataView: {}
            }
          },
          tooltip: {
            trigger: "item",
            formatter: "{b} : {c}",
          },
          dataZoom: [{
            type: "slider",
            show: true,
            startValue: 0,
            endValue: 6,
          }],
          xAxis: {
            type: "category",
            data: xdata.map((item) => {
              return item.name.split("(")[0];
            }),
          },
          // y轴
          yAxis: [
            {
              type: "value",
              splitLine: {
                show: true,
                lineStyle: {
                  color: "#E0E6F1",
                },
              },
              axisLabel: {
                color: "#6E7079",
              },
            },
          ],
          series: [
            {
              name: "任务数量",
              type: "bar",
              data: xdata.map((item) => {
                return item.series1; //任务数量
              }),
              barWidth: fontSize(20),
              itemStyle: {
                color: "#5470C6",
              },
            },
            {
              name: "接送人数",
              type: "bar",
              data: xdata.map((item) => {
                return item.series2; //接送人数
              }),
              barWidth: fontSize(20),
              itemStyle: {
                color: "#91CC75",
              },
            },
          ],
        };


      // console.log(option, "option");
      // console.log(driversummaryChart, "driversummaryChart");
      option && driversummaryChart.setOption(option);
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      // window.addEventListener("resize", function () {
      //   // console.log(123);
      //   driversummaryChart.resize();
      // });
    },

    // 统计
    async transportScheduleCount() {
      if (this.ldtime && this.ldtime.length != 0) {
        this.regitsterInfo.startTime = this.ldtime[0].toString() + " 00:00:00";
        this.regitsterInfo.endTime = this.ldtime[1].toString() + " 23:59:59";
      } else {
        this.regitsterInfo.startTime = "";
        this.regitsterInfo.endTime = "";
      }
      let params = {
        startTime: this.regitsterInfo.startTime,
        endTime: this.regitsterInfo.endTime,
      };
      if (this.transportOrgCode != "") {
        params.transportOrgCode = this.transportOrgCode;
      }
      // console.log(params,'打印 头部查询条件的params')
      let classDistributednub = 0;
      let communityCountnub1 = 0;
      let communityCountnub2 = 0;
      let isolationDistributednub = 0;
      let driverCountNub1 = 0;
      let driverCountNub2 = 0;

      try {
        let { data } = await transportScheduleCount(params);
        if (data.code == 200) {
          this.numCount = data.data.numCount;
          this.classDistributed = data.data.classDistributed;
          this.communityCount = data.data.communityCount;
          this.isolationDistributed = data.data.isolationDistributed;
          this.driverCount = data.data.driverCount;
          for (let i of this.classDistributed) {
            classDistributednub = +i.value + classDistributednub;
          }
          for (let i of this.communityCount) {
            communityCountnub1 = +i.series1 + communityCountnub1;
            communityCountnub2 = +i.series2 + communityCountnub2;
          }
          for (let i of this.isolationDistributed) {
            isolationDistributednub = +i.value + isolationDistributednub;
          }
          for (let i of this.driverCount) {
            driverCountNub1 = +i.series1 + driverCountNub1;
            driverCountNub2 = +i.series2 + driverCountNub2;
          }
          this.driverCountNub1 = driverCountNub1;
          this.driverCountNub2 = driverCountNub2;

          this.classDistributednub = classDistributednub;
          this.isolationDistributednub = isolationDistributednub;
          this.communityCountnub1 = communityCountnub1;
          // console.log("🚀 ~ file: TransitSchedulingStatistics.vue ~ line 383 ~ transportScheduleCount ~ communityCountnub1", communityCountnub1)
          this.communityCountnub2 = communityCountnub2;
          // console.log("🚀 ~ file: TransitSchedulingStatistics.vue ~ line 385 ~ transportScheduleCount ~ communityCountnub2", communityCountnub2)

          this.adsadadas();
        }
      } catch (error) { }
    },

    adsadadas() {
      this.rqfl();
      this.gldb();
      this.sqws();
      this.driver();
    },
    // 导出
    escalateExport() {
      if (this.ldtime && this.ldtime.length != 0) {
        this.regitsterInfo.startTime = this.ldtime[0].toString() + " 00:00:00";
        this.regitsterInfo.endTime = this.ldtime[1].toString() + " 23:59:59";
      } else {
        this.regitsterInfo.startTime = "";
        this.regitsterInfo.endTime = "";
      }

      let params = Object.assign({
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        startTime: this.regitsterInfo.startTime,
        endTime: this.regitsterInfo.endTime,
        transportOrgCode: this.transportOrgCode,
      });

      messageBox(
        () => {
          this.fullscreenLoading = true;
          exportList(params).then((res) => {
            // console.log(11111111111, res);
            this.fullscreenLoading = false;
            importFile(res.data, "转运调度");
          });
        },
        "warning",
        "是否确认导出 转运调度 数据？"
      );
    },
  },
};
</script>
<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
}

.row {
  width: 100%;
  display: flex;

  >.box-card {
    margin-right: 20px;
  }

  & {
    margin-bottom: 20px;
  }

  &:nth-of-type(n + 3) /deep/ .el-card__body {
    padding: 0;
  }
}

.zyrqfb {
  width: 100%;
  display: flex;
  justify-content: start;
  margin-top: 20px;
}

#transportPopulationsEcharts {
  width: 600px;
  height: 300px;
  /* 250/192 */
}

// .transportPopulationsEcharts {
//   width: 600px;
//   // height: 300px;
//   display: flex;
//   justify-content: start;
// }
#includeDriver {
  width: 600px;
  overflow: hidden;
  overflow-x: scroll;

  #driversummary {
    width: 600px;
    height: 300px;
    color: rgb(245, 245, 245);
    /* 250/192 */
  }
}

#distributionIsolation {
  width: 600px;
  height: 300px;

  /* 250/192 */
}

// .distributionIsolation {
//   width: 600px;
//   display: flex;
//   justify-content: start;
// }
#includeSummary {
  // border: 1px solid red;
  width: 600px;
  overflow: hidden;
  overflow-x: scroll;

  #summary {
    width: 600px;
    height: 300px;
    /* 250/192 */
  }
}

// .summary {
//   width: 600px;
//   height: 300px;
//   display: flex;
//   justify-content: start;
// }

.biaotou {
  background-color: #f9f9f9;
}

.biaotou td {
  width: 160px;
  height: 40px;
}

// table {
//   border-collapse: collapse;
// }

// tr,
// td {
//   border: 1px solid #ccc;
//   width: 160px;
//   height: 40px;
//   white-space: nowrap;
// }

// td {
//   // width: 50%;
// }

table tbody {
  height: 200px;
  overflow-y: auto;
  display: block;
}

table thead,
tbody tr {
  display: table;
  width: 100%;
}

table {
  border-collapse: collapse;
  font-size: 14px;

  .table-body {
    height: 300px;
    display: block;
    overflow-y: auto;
    // overflow: hidden;
  }

  .table-body td {
    width: 160px;
    height: 40px;
  }

  tr:nth-child(1) {
    td {
      background: #f5f5f5;
      font-weight: bold;
    }
  }

  tr:nth-last-child(1) {
    // position: fixed;
    background: #f5f5f5;
  }

  td {
    width: 270px;
    height: 36px;
    opacity: 1;
    background: #fff;
    color: #333333;
    border: 1px solid #ccc;
    text-align: center;
  }
}

.zyrqfb_table {
  text-align: center;
  margin-left: 50px;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}

.element.style {
  left: -100px;
}
</style>