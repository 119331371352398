// 引入工具函数
import {
  http,
  httpimg
} from '../../api/index'

// 统计
export const transportScheduleCount = params => http.post('/ohealth/api/v1/epidemicControl/transport-schedule/transportScheduleCount', params)

// 导出
export const exportList = params => httpimg.post('/ohealth/api/v1/epidemicControl/transport-schedule/exportList', params)




